import Logo from "./Logo";

import styles from "./Header.module.scss";
import HeaderImage from "./HeaderImage";

function Header() {
  return (
    <div className={styles["header"]}>
      <Logo />
      <HeaderImage
        title="Basement Remodel : 2018"
        desc="This full basement remodel is on the larger side of projects we do. New floooring, recessed lighting, paint, and wood accent walls were added to modernize the space."
      />
    </div>
  );
}

export default Header;
