import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import "./App.css";
import Body from "./components/Body/Body";
import ProjectGallery from "./components/Body/ProjectGallery";
// import Footer from "./components/Footer/Footer";
import TaskList from "./components/Body/TaskList";
import ContactUs from "./components/Footer/ContactUs";
import Header from "./components/Header/Header";

function App() {
  return (
    <MantineProvider>
      <Notifications />
      <div className="app">
        <header className="app-header">
          <Header />
        </header>
        <main className="app-body">
          <Body>
            <p>
              Southern County Handyman provides handyman and home services in
              South Jersey.
            </p>
            <p>
              You probably have a list of projects around the house waiting to
              be completed. Maybe they are just beyond your DIY reach, or maybe
              you just don&apos;t have the time to get to them. We know how
              difficult it can be to find someone willing to take on small and
              mid-size jobs. Whether it&apos;s an overdue repair, or time to
              upgrade your plumbing or electrical fixtures, we can help cross it
              off your list.
            </p>
          </Body>
          <ProjectGallery />
          <TaskList />
        </main>
        <footer className="app-footer">
          <ContactUs />
          {/* <Footer /> */}
        </footer>
      </div>
    </MantineProvider>
  );
}

export default App;
