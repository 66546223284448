import styles from "./TaskList.module.scss";

function TaskList() {
  const tasks = [
    "Picture & Shelf Hanging",
    "Ceiling Fan Installation",
    "TV Mounts",
    "Toilets and Plumbing Fixtures",
    "Garbage Disposals",
    "Caulking",
    "Mailboxes",
    "Smart Locks",
    "Dimmer Switches",
    "Lighting Fixtures",
    "Recessed Lighting",
    "Tile Backsplashes",
    "Blind & Curtain Hanging",
    "Interior Painting",
    "Drywall Patch & Repair",
    "Furniture Assembly",
  ];

  return (
    <div className={styles["typical-projects"]}>
      <h2 className={styles["typical-projects_header"]}>Typical Projects</h2>
      <ul className={styles["typical-projects_list"]}>
        {tasks.map((task) => {
          return <li key={task}>{task}</li>;
        })}
      </ul>
    </div>
  );
}

export default TaskList;
