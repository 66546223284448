import styles from "./ProjectSample.module.scss";

function ProjectSample({ thumb, title, skillset }) {
  return (
    <div className={styles["project-sample"]}>
      <h4 className={styles["project-sample_title"]}>{title}</h4>
      <img className={styles["project-sample_img"]} src={thumb} alt={title} />
      <ul className={styles["project-sample_list"]}>
        {skillset.map((skill) => {
          return <li key={skill}>{skill}</li>;
        })}
      </ul>
    </div>
  );
}

export default ProjectSample;
