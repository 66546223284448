import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "@mantine/form";
import { Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";

import styles from "./ContactUs.module.scss";

function ContactUs() {
  const form = useRef();
  const [submitted, isSubmitted] = useState(false);

  //no need for state of form data
  /*
  const [userFormData, setFormData] = useState({
    userName: "",
    userEmail: "",
    userPhone: "",
    userMessage: "",
  });
  */

  //will need to add this back to work on validation
  const userForm = useForm({
    validateInputOnBlur: ["userName", "userEmail", "userPhone", "userMessage"],
    initialValues: {
      userName: "", //userFormData.userName,
      userEmail: "", //userFormData.userEmail,
      userPhone: "", //userFormData.userPhone,
      userMessage: "", //userFormData.userMessage,
    },
    validate: {
      userName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      userEmail: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      userMessage: (value) =>
        value === "Please describe your project"
          ? "Please describe your project"
          : null,
    },
  });

  // not validating on each keypress
  /*
  function handleInputChange(event) {
    console.log(`Changed ${event.target.value}`);
    setFormData({ ...userFormData, [event.target.name]: event.target.value });
  }
  */

  const sendEmail = (e) => {
    //not necessary with mantine form
    //e.preventDefault();

    //for testing only
    /*
    notifications.show({
      message: `${form.current}`,
      color: "red",
    });
    isSubmitted(true);
*/
    //real form submission

    emailjs
      .sendForm(
        "service_8pmtdbb",
        "template_5khu729",
        form.current,
        "N3OrdQqNthR9g3rAe"
      )
      .then(
        (result) => {
          console.log(result.text);
          isSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleErrors = (errors) => {
    if (errors.userName) {
      notifications.show({
        message: "Please enter a valid name",
        color: "red",
      });
    } else if (errors.userEmail) {
      notifications.show({
        message: "Please enter a valid email",
        color: "red",
      });
    } else if (errors.userMessage) {
      notifications.show({
        message: "Please describe your project",
        color: "red",
      });
    } else {
      //sendEmail();
    }
  };

  return (
    <div className={styles["contact-us"]}>
      <div className={styles["contact-us_left"]}>
        <h4 className={styles["contact-us_title"]}>CONTACT US</h4>
        <p>
          Interested in getting started with a project similar to the ones
          above? Want to know more about other work we can do? Enter your
          information to have someone reach out to you.
        </p>
      </div>
      <div className={styles["contact-us_right"]}>
        {!submitted ? (
          <form
            className={styles["request-form"]}
            ref={form}
            //onSubmit={sendEmail}
            onSubmit={userForm.onSubmit(sendEmail, handleErrors)}
          >
            <div className={styles["request-form_col"]}>
              <input
                aria-label="Enter Your Name"
                placeholder="Name"
                id="userName"
                name="userName"
                className={styles["request-form_input"]}
                {...userForm.getInputProps("userName")}
                // onChange={handleInputChange}
              />
              <input
                aria-label="Enter Your Email"
                placeholder="Email"
                id="userEmail"
                name="userEmail"
                className={styles["request-form_input"]}
                // onChange={handleInputChange}
                {...userForm.getInputProps("userEmail")}
              />
              <input
                aria-label="Enter Your Phone"
                placeholder="Phone"
                id="userPhone"
                name="userPhone"
                className={styles["request-form_input"]}
                // onChange={handleInputChange}
                {...userForm.getInputProps("userPhone")}
              />
            </div>
            <div className={styles["request-form_col"]}>
              <textarea
                aria-label="Enter Project Details"
                multiline="true"
                id="userMessage"
                name="userMessage"
                placeholder="Please describe your project"
                className={styles["request-form_textarea"]}
                // onChange={handleInputChange}
                {...userForm.getInputProps("userMessage")}
              />
              <Button type="submit" className={styles["submit-btn"]}>
                SUBMIT
              </Button>
            </div>
          </form>
        ) : (
          <ThankYou />
        )}
      </div>
    </div>
  );

  function ThankYou() {
    return (
      <div className={styles["thank-you"]}>
        <h5 className={styles["thank-you_title"]}>
          Thank you for your submission
        </h5>
        <p>
          We understand that many requests for small to mid-size jobs go
          unanswered at larger companies. Even if this job isn't right for us,
          we will respond to let you know.
        </p>
      </div>
    );
  }
}

export default ContactUs;
