import styles from "./Logo.module.scss";

function Logo() {
  return (
    <div className={styles["logo-outline"]}>
      <h1 className={styles["logo-type"]}>
        Southern
        <br />
        County
        <br />
        Handyman
      </h1>
    </div>
  );
}

export default Logo;
