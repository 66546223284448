import ProjectSample from "./ProjectSample";
import styles from "./ProjectGallery.module.scss";
import Thumb1 from "../../images/thumb_basement.jpg";
import Thumb2 from "../../images/thumb_kitchen.jpg";
import Thumb3 from "../../images/thumb_family.jpg";

function ProjectGallery() {
  const images = [
    {
      title: "Basement Remodel : 2018",
      thumb: Thumb1,
      skillset: [
        "Fireplace Install",
        "Flooring",
        "Paint",
        "Wood Plank Wall",
        "Recessed Lighting",
        "Furniture Assembly",
      ],
    },
    {
      title: "Kitchen Remodel : 2021",
      thumb: Thumb2,
      skillset: [
        "Cabinet Install",
        "Flooring",
        "Crown Molding",
        "Paint",
        "Custom Floating Shelves",
        "Tile Backsplash",
      ],
    },
    {
      title: "Fireplace Upgrade : 2016",
      thumb: Thumb3,
      skillset: [
        "Fireplace Door Install",
        "Textured Wallpaper",
        "Paint",
        "Picture Hanging",
      ],
    },
  ];

  return (
    <div className={styles["project-gallery"]}>
      <h2>Recent Projects</h2>
      <div className={styles["project-gallery_thumbs"]}>
        {images.map((img) => {
          return (
            <ProjectSample
              thumb={img.thumb}
              key={img.title}
              title={img.title}
              skillset={img.skillset}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ProjectGallery;
