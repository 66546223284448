import styles from "./HeaderImage.module.scss";

import HeaderImageSrc from "../../images/header.jpg";

function HeaderImage({ desc, title }) {
  return (
    <div className={styles["header-img"]}>
      <div className={styles["header-img_overlay"]}>
        <h4 className={styles["header-img_overlay-title"]}>{title}</h4>
        <p className={styles["header-img_overlay-desc"]}>{desc}</p>
      </div>
      <img
        className={styles["header-img_img"]}
        src={HeaderImageSrc}
        alt="Basement"
      />
    </div>
  );
}

export default HeaderImage;
